import React from 'react';
import Layout from '../components/layout';
import { Helmet } from 'react-helmet';

export default function Contact() {
  return (
    <Layout>
      <h1>Contact</h1>
      <p>
        As usual, you can find me on{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.linkedin.com/in/dominik-matis/"
        >
          LinkedIn
        </a>
        .
      </p>
      <p>
        I'm also active on Facebook, my profile is{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.facebook.com/domosino/"
        >
          here
        </a>
        .
      </p>
      <p>
        If you want to email me, you can do so{' '}
        <a target="_blank" rel="noreferrer" href="mailto:domosino44@gmail.com">
          here
        </a>
        .
      </p>
      <p>
        This site is built using Gatsby and deployed on GitLab Pages. Code for
        this website is available in my{' '}
        <a target="_blank" rel="noreferrer" href="https://gitlab.com/dmatis">
          GitLab account
        </a>
        . Also, I have an account on{' '}
        <a href="https://github.com/dmatis2" target="_blank" rel="noreferrer">
          GitHub
        </a>
        .
      </p>
      <Helmet>
        <title>Contact</title>
      </Helmet>
    </Layout>
  );
}
